.BaseButton.CartButton {
  border-radius: 50%;
  border: 2px solid white;
  font-size: 24px;

  width: 60px;
  height: 60px;
}

.BaseButton.CartButton:hover {
  border: 2px solid green;
  color: yellowgreen;
  font-size: 28px;
}

.CartCount.ProductsCount0 {
  display: none;
}

.CartBlock:hover .HoverBlock {
  display: block;
}
