.BaseButton {
  border: 1px solid lightblue;
  color: white;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05px;
  text-align: "center";
  padding: 10px;

  height: 65px;
}

.BaseButton:hover {
  color: darkturquoise;
  border-color: lightgoldenrodyellow;
}

.BaseButton.StyleRounded {
  border-radius: 50px;
}

.BaseButton.StyleCircle {
  border-radius: 50%;
}

.BaseButton.StyleLight {
  color: black;
  background-color: white;
  border-color: white;
}

.BaseButton.StyleLight:hover {
  color: black;
  background-color: lightyellow;
  border-color: mediumseagreen;
  letter-spacing: 0.4px;
}

.BaseButton.StyleNoBorder {
  border: none;
}

.BaseButton.StyleFreeHeight {
  height: auto;
}
