.MenuPanel.PageTibetanWisdom {
  height: 128px;
}

.MenuPanel.PageTibetanWisdom .MenuPointWrapper.PointBack {
  display: block;
  left: 70px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageTibetanWisdom .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
  width: 125px;
  height: 125px;
}

.MenuPanel.PageTibetanWisdom .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 4px);
}

.AppMobile .MenuPanel.PageTibetanWisdom .MenuPointWrapper.PointBack {
  left: 5px
}

.AppMobile .MenuPanel.PageTibetanWisdom .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageTibetanWisdom .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px; 
}

.AppMobile .MenuPanel.PageTibetanWisdom .MenuPointWrapper.PointLogo {
  width: 100px;
  height: 100px;

  left: calc(100% / 2 - 50px);
  top: calc(100% / 2 - 50px);
}

/***************************************************/

.TibetanWisdomBlock {
  padding: 45px 10% 30px 10%;
}

.TibetanWisdomBlock .PageTitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0.25em;
}

.TibetanWisdomBlock .PageDescr {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;

  text-align: justify;
  gap: 3px;
  text-transform: lowercase;
}

.TibetanWisdomBlock .Underlined {
  border-bottom: 1px solid white;
  width: 80px;
  margin-top: 8px;
  margin-bottom: 30px;
}

.TibetanWisdomMobileBlock {
  gap: 70px;
  padding-bottom: 100px;
}

.MobileArticleBlock {
  margin-top: -30px;
  background-color: rgba(0, 0, 0, 0.5);
}
