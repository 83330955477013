.MenuPanel {
  width: 100%;
  height: 120px;
  position: relative;
  transition: height 1s;
}

.MenuPanel .MenuPointWrapper {
  display: none;
  left: 0;
  top: 0;
  position: absolute;

  transition: left 1.5s, top 1.5s, width 1.5s, height 1.5s, padding 1.5s;
}

.MenuPanel.PageGallery .MenuPointWrapper.PointLogo {
  transition: left 0.1s, top 1.5s, height 1.5s, padding 1.5s;
}

.MenuPanel .PointLogo {
  opacity: 0.9;
  width: 125px;
  height: 125px;
}

.MenuPanel .PointLogo:hover {
  opacity: 1;
}
