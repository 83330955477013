.MenuPanel.PageThangka {
  height: 0;
}

/* .MenuPanel.PageThangka .MenuPointWrapper.PointTwoLines {
  display: block;
  left: 120px;
  top: calc(100% / 2 - 7px);
}

.MenuPanel.PageThangka .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
  width: 125px;
  height: 125px;
}

.MenuPanel.PageThangka .MenuPointWrapper.PointCart {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 30px);
} */

/************************************************/
.ThangkaBlock {
  height: calc(100%);
}

.ThangkaBlock .EmptyBlock {
  width: 10%;
  flex-shrink: 0;
}

.ThangkaBlock .EmptyBlock .ButtonBackWrapper {
  padding-right: 30px;
  padding-top: 50px;
}

.ThangkaBlock .ThangkaDescr {
  width: 25%;
  max-width: 300px;
  flex-shrink: 0;
}

.ThangkaBlock .ThangkaDescr .StatueName {
  font-size: 32px;
  justify-content: space-between;
}

.ThangkaBlock .ThangkaDescr .StatueNameUnderlined {
  margin-top: 10px;
  margin-bottom: 40px;
  width: 30%;
  border-bottom: 1px solid white;
}

.ThangkaBlock .ThangkaDescr .DescriptionText {
  font-size: 14px;
  text-align: justify;
  padding-bottom: 20px;
  gap: 3px;
  text-transform: lowercase;
  min-height: 50%;
}
