.RadioButtonsBlock {
  font-family: Inter;
  background-color: black;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  border: 0.5px solid grey;
}

.RadioButtonsBlock .RadioButtonsValues {
  gap: 5px;
  padding-top: 10px;
}

.RadioButtonsBlock .ValueBlock:hover {
  opacity: 0.7;
}
