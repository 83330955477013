/*****************************************/
.MenuPanel.PageGallery {
  height: 128px;
}

.MenuPanel.PageGallery .MenuPointWrapper.PointBack {
  display: block;
  left: 70px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageGallery .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
}

.MenuPanel.PageGallery .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 4px);
}

.AppMobile .MenuPanel.PageGallery .MenuPointWrapper.PointBack {
  left: 5px
}

.AppMobile .MenuPanel.PageGallery .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageGallery .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px; 
}

.AppMobile .MenuPanel.PageGallery .MenuPointWrapper.PointLogo {
  width: 100px;
  height: 100px;

  left: calc(100% / 2 - 50px);
  top: calc(100% / 2 - 50px);
}

/******************************************/

.GalleryWrapper {
  height: calc(100% - 128px);
  overflow-y: hidden;
  padding: 0 20px 0 20px;
}

.GalleryImageWrapper {
  overflow-y: hidden;
}

.GalleryImageWrapper:hover + .GalleryStatueDescription .GalleryStatueEye,
.GalleryStatueDescription:hover .GalleryStatueEye {
  background-color: lightyellow;
}

.AnimationCell:nth-child(2) .GalleryStatueBlock {
  border-left: 1px solid gray;
  border-right: 1px solid gray;

  border-image: linear-gradient(to top, 
  black,
    gray,
    black
  ) 1;
}

.GalleryImageWrapper img {
  width: 100%;
  padding: 0 10px 0 10px;

  /* border: 1px solid yellow; */
}

.GalleryStatueDescription {
  margin: 20px 0 20px 0;
}

.GalleryStatueLabel {
  opacity: 0.3;
  font-size: 14px;
}

.GalleryStatueEye {
  margin-right: 10px;
  padding: 0 1px 1px 1px;
}

.GalleryStatueEye:hover {
  opacity: 0.6;
}

.CartHoverBlock {
  display: none;
  background-color: white;
  opacity: 0.5;
  border-radius: 10px;
  top: 160%;
  right: -100px;
  height: 500px;
  width: 300px;
}

/************************************/
.MobileGalleryBlock {
  padding-top: 30px;
}

.MobileGalleryBlock .MobileGalleryImageWrapper {
  border-left: 1.5px solid white;
  border-right: 1.5px solid white;

  border-image: linear-gradient(to top, 
  black,
    white,
    black
  ) 1;

  min-height: calc(60vh);
}



.EyeStatue {
  transform: scale(175%);
  width: 50px;
  height: 50px;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 50%;
}

.EyeStatue:hover {
  background-color: rgba(32, 32, 32, 0.8)
}

