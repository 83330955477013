.ImagesAnimation {
  overflow: hidden;
}

.ImagesAnimation.ItemsInRow3.State_1 .AnimationRow .AnimationCell:nth-child(1) > * {
  animation: leftUp 1.5s;
}

.ImagesAnimation.ItemsInRow3.State_1 .AnimationRow .AnimationCell:nth-child(2) > * {
  animation: middleUp 1.5s;
}

.ImagesAnimation.ItemsInRow3.State_1 .AnimationRow .AnimationCell:nth-child(3) > * {
  animation: rightUp 1.5s;
}

.ImagesAnimation.ItemsInRow3.State_3 .AnimationRow .AnimationCell:nth-child(1) > * {
  animation: leftDown 1.5s;
}

.ImagesAnimation.ItemsInRow3.State_3 .AnimationRow .AnimationCell:nth-child(2) > * {
  animation: middleDown 1.5s;
}

.ImagesAnimation.ItemsInRow3.State_3 .AnimationRow .AnimationCell:nth-child(3) > * {
  animation: rightDown 1.5s;
}

.ImagesAnimation.ItemsInRow2.State_1 .AnimationRow .AnimationCell:nth-child(1) > * {
  animation: middleUp 1.5s;
}

.ImagesAnimation.ItemsInRow2.State_1 .AnimationRow .AnimationCell:nth-child(2) > * {
  animation: rightUp 1.5s;
}

.ImagesAnimation.ItemsInRow2.State_3 .AnimationRow .AnimationCell:nth-child(1) > * {
  animation: middleDown 1.5s;
}

.ImagesAnimation.ItemsInRow2.State_3 .AnimationRow .AnimationCell:nth-child(2) > * {
  animation: rightDown 1.5s;
}


@keyframes leftUp {
  0% {
    top: 0;
  }

  50% {
    top: -30%;
  }

  100% {
    top: -100%;
  }
}

@keyframes middleUp {
  0% {
    top: 0;
  }

  50% {
    top: -60%;
  }

  100% {
    top: -100%;
  }
}

@keyframes rightUp {
  0% {
    top: 0;
  }

  50% {
    top: -90%;
  }

  100% {
    top: -100%;
  }
}

@keyframes leftDown {
  0% {
    top: 0;
  }

  50% {
    top: 30%;
  }

  100% {
    top: 100%;
  }
}

@keyframes middleDown {
  0% {
    top: 0;
  }

  50% {
    top: 60%;
  }

  100% {
    top: 100%;
  }
}

@keyframes rightDown {
  0% {
    top: 0;
  }

  50% {
    top: 90%;
  }

  100% {
    top: 100%;
  }
}

/**************************************************************/
.ImagesAnimation.ItemsInRow4 .AnimationCell {
  width: 25%;
}

.ImagesAnimation.ItemsInRow3 .AnimationCell {
  width: 33%;
}

.ImagesAnimation.ItemsInRow2 .AnimationCell {
  width: 50%;
}

.ImagesAnimation .AnimationCell > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.ImagesAnimation:not(.State0) .AnimationCell > * {
  transition: top 0.9s;
}
