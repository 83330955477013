.MenuPanel.PageLotusFlower {
  height: 128px;
}

.MenuPanel.PageLotusFlower .MenuPointWrapper.PointBack {
  display: block;
  left: 70px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageLotusFlower .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
  width: 125px;
  height: 125px;
}

.MenuPanel.PageLotusFlower .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 4px);
}

.AppMobile .MenuPanel.PageLotusFlower .MenuPointWrapper.PointBack {
  left: 5px;
}

.AppMobile .MenuPanel.PageLotusFlower .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageLotusFlower .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px;
}

.AppMobile .MenuPanel.PageLotusFlower .MenuPointWrapper.PointLogo {
  width: 100px;
  height: 100px;

  left: calc(100% / 2 - 47px);
  top: calc(100% / 2 - 47px);
}

/***********************************/

.LotusFlowerPage .FirstImageBlock {
  min-height: 80% 
}

.LotusFlowerPage .SecondImage {
  width: 75%;
  padding-left: 100px;
}

.LotusFlowerPage .SecondImage img {
  margin-top: -70px;
}

.WisdomPageMobileBlock.LotusFlowerPage .WisdomPageArticle:nth-child(1) {
  padding-right: 80px;
}

.WisdomPageMobileBlock.LotusFlowerPage .WisdomPageArticle:nth-child(2) {
  padding-left: 80px;
}
