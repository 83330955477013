.ButtonBack svg {
  height: 40px;
  fill: white;
  opacity: 0.8;
}

.ButtonBack:hover svg {
  opacity: 1;
}

.ButtonBack.AsLabel {
  font-size: 50px;
  opacity: 0.8;
}

.ButtonBack:hover,
.ButtonBack:hover svg {
  opacity: 1;
}

.BackButtonRound {
  width: 50px;
  height: 50px;
  font-size: 30px;
  padding-bottom: 14px;
}
