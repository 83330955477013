.TwoLinesMenu {
  position: relative;
  min-width: 60px;
  min-height: 15px;
}

.TwoLinesLine {
  width: 64px;
  font-size: 10px;
  border-top: white 1px solid;

  position: absolute;
  left: 0;

  transition: transform 1.5s;
}

.App:not(.AppMobile) .TwoLinesMenu:hover .TwoLinesLine {
  border-color: green;
}

.TwoLinesMenu .TwoLinesLine:nth-child(2) {
  margin-top: 8px;
}

.TwoLinesMenu.Cross {
  left: -50px;
  min-height: 30px;
}
.TwoLinesMenu.Cross .TwoLinesLine {
  width: 50px;
}

.TwoLinesMenu.Cross .TwoLinesLine:nth-child(1) {
  transform: rotate(45deg);
  padding-right: 40px;
}

.TwoLinesMenu.Cross .TwoLinesLine:nth-child(2) {
  transform: rotate(-45deg);
}

.TwoLinesMenu.Cross .TwoLinesLine:nth-child(2) {
  margin-top: 0;
}
