.DesctopFooter {
  padding: 150px 50px 50px 50px;
  justify-content: center;
  gap: 50px;
}

.DesctopFooter a {
  color: white;
  opacity: 0.8;
}

.DesctopFooter a:hover {
  opacity: 1;
}

.DesctopFooter .FooterPart {
  padding: 20px;
}

.FooterTitle {
  font-size: 30px;
  padding-top: 11px;
  height: 50%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-size: 40px;
  line-height: 48px;

  color: rgba(255, 255, 255, 0.8);
}

.FooterLink,
.FooterLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* or 21px */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.8);
}

.FooterPart .BaseButton.FooterLink,
.FooterPart .FooterLabel {
  padding-left: 7px;
  height: 30px;
  justify-content: left;
}

.FooterPart .BaseButton.FooterLink {
  text-decoration: underline rgba(255, 255, 255, 0.8) 0.5px;
}

.FooterPart .FooterLabel {
  text-decoration-line: none;
}

.MobileFooter {
  padding-top: 60px;
  gap: 50px;
}

.MobileFooter .FooterTitle {
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 28px;
}

.MobileFooter .FooterLink,
.MobileFooter .FooterLabel {
  color: white;
}

.MobileFooter .FooterLink:hover {
  color: rgba(255, 255, 255, 0.8);
}

.MobileFooter .LastLabel {
  padding: 30px;
}
