html,
body,
#root,
.App {
  background-color: black;
  color: white;
  width: 100%;
  height: 100%;
}

body {
  overscroll-behavior-y: contain;
}

.App.AppMobile {
  padding-left: calc((100% / 2) - 200px);
  padding-right: calc((100% / 2) - 200px);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.FontEmilea {
  font-family: "Emilea";
}

.FontInter {
  font-family: "Inter Inter-Light";
}

.FontInterOnly {
  font-family: "Inter";
}

.FlexV {
  display: flex;
  flex-direction: column;
}

.FlexH {
  display: flex;
  flex-direction: row;
}

.Centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.JustifyCenter {
  justify-content: center;
}

.AlignCenter {
  align-items: center;
}

.AlignRight {
  text-align: right;
}

.Grow {
  flex-grow: 1;
}

.ConditionallyGrow {
  display: none;
}
.PositionV-Bottom > .ConditionallyGrow,
.PositionH-Right > .ConditionallyGrow {
  flex-grow: 1;
  display: block;
}

.FullWidth {
  width: 100%;
  box-sizing: border-box;
}
.FullHeight {
  height: 100%;
  box-sizing: border-box;
}
.FullSize {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.ScrollV {
  overflow-y: scroll;
}

.ScrollV::-webkit-scrollbar {
  display: none;
}

.NoScrollV {
  overflow-y: hidden;
}

.NoShrink {
  flex-shrink: 0;
}

.FontBold {
  font-weight: bold;
}

.FontLarger {
  font-size: larger;
}

.FontSmaller {
  font-size: smaller;
}

.Margin3 {
  margin: 3px;
}
.Margin5 {
  margin: 5px;
}
.Margin7 {
  margin: 7px;
}
.Margin10 {
  margin: 10px;
}
.Margin15 {
  margin: 15px;
}
.Margin20 {
  margin: 20px;
}

.MarginLeft3 {
  margin-left: 3px;
}
.MarginLeft5 {
  margin-left: 5px;
}
.MarginLeft10 {
  margin-left: 10px;
}

.ChildrenGap3 {
  gap: 3px;
}
.ChildrenGap5 {
  gap: 5px;
}
.ChildrenGap7 {
  gap: 7px;
}
.ChildrenGap10 {
  gap: 10px;
}
.ChildrenGap15 {
  gap: 15px;
}
.ChildrenGap20 {
  gap: 20px;
}

.MarginTop3 {
  margin-top: 3px;
}
.MarginTop5 {
  margin-top: 5px;
}
.MarginTop10 {
  margin-top: 10px;
}
.MarginTop20 {
  margin-top: 20px;
}

.Padding3 {
  padding: 3px;
}
.Padding5 {
  padding: 5px;
}
.Padding7 {
  padding: 7px;
}
.Padding10 {
  padding: 10px;
}
.Padding15 {
  padding: 15px;
}
.Padding20 {
  padding: 20px;
}

.PaddingLeft3 {
  padding-left: 3px;
}
.PaddingLeft5 {
  padding-left: 5px;
}
.PaddingLeft10 {
  padding-left: 10px;
}

.a {
  color: green;
  cursor: pointer;
}

.a:hover {
  color: darkkhaki;
}

.CursorPointer:hover {
  cursor: pointer;
}

.BlockTop {
  padding-top: 10px;
  border-top: solid 1px lightgrey;
}

.BlockLeft {
  padding-left: 10px;
  border-left: solid 1px lightgrey;
}

.PosRelative {
  position: relative;
}
.PosAbsolute {
  position: absolute;
}
.AbsoluteCopy {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Invisible {
  display: none;
}

.TextUpper {
  text-transform: uppercase;
}

.TextLower {
  text-transform: lowercase;
}

.UseTest .Orange {
  background-color: orange;
}
.UseTest .Green {
  background-color: green;
}
.UseTest .Yellow {
  background-color: yellow;
}
.UseTest .Blue {
  background-color: blue;
}
