.EyeBlock {
  width: 25px;
  height: 25px;
}

.Eye {
  width: 8px;
  height: 8px;
  border: 1px solid white;
  border-radius: 50%;
}

.EyeBlock:hover .Eye {
  border-color: yellowgreen;
}

.EyeBlock .EyeBorderContainer svg {
  fill: white
}

.EyeBlock:hover .EyeBorderContainer svg {
  fill: yellowgreen;
}
