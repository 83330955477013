.SmallArticleBlock .ArticleTitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  overflow-wrap: normal;
}

.SmallArticleBlock .ArticleContent {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;

  text-align: justify;
  gap: 3px;
  text-transform: lowercase;
}

.SmallArticleBlock .ArticleUnderlined {
  border-bottom: 1px solid white;
  width: 80px;
  margin-top: 8px;
  margin-bottom: 30px;
}
