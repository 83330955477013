/*****************************************/
.MenuPanel.PageOurTeam {
  height: 128px;
}

.MenuPanel.PageOurTeam .MenuPointWrapper.PointBack {
  display: block;
  left: 80px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageOurTeam .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
}

.MenuPanel.PageOurTeam .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 80px - 64px);
  top: calc(100% / 2 - 4px);
}

.AppMobile .MenuPanel.PageOurTeam .MenuPointWrapper.PointBack {
  left: 5px
}

.AppMobile .MenuPanel.PageOurTeam .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageOurTeam .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px; 
}

.AppMobile .MenuPanel.PageOurTeam .MenuPointWrapper.PointLogo {
  width: 100px;
  height: 100px;

  left: calc(100% / 2 - 50px);
  top: calc(100% / 2 - 50px);
}

/**********************************************/
.OurTeamBlock {
  margin: 0 120px 0 120px;
  font-family: Inter;
}

.OurTeamMobileBlock {
  font-family: Inter;
}

.OurTeamMainTitle {
  font-size: 64px;
  font-weight: 100;
  letter-spacing: 0.1em;
}

.OurTeamBlock .OurTeamMainTitle {
  padding-top: 30px;
}

.OzenBlock {
  padding-top: 50px;
}

.OzenBlock .OzenDescriptionBlock {
  max-width: 30%;
}

.OzenName {
  font-size: 32px;
  letter-spacing: 0.15em;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 30px;
}

.OzenDescription {
  font-size: 12px;
  text-align: justify;
}

.Videos {
  padding-top: 50px;
}

.Videos .VideoWrapper {
  padding: 10px;
  width: 50%;
}

.MobilePeopleBlock {
  padding-bottom: 100px;
}
