.MenuPanel.PageStatueItem3D {
  height: 158px;
}

.MenuPanel.PageStatueItem3D .MenuPointWrapper.PointBack {
  display: block;
  left: 70px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageStatueItem3D .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
}

.MenuPanel.PageStatueItem3D .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 4px);
}

.AppMobile .MenuPanel.PageStatueItem3D .MenuPointWrapper.PointBack {
  left: 5px;
}

.AppMobile .MenuPanel.PageStatueItem3D .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageStatueItem3D .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px;
}

.AppMobile .MenuPanel.PageStatueItem3D .MenuPointWrapper.PointLogo {
  width: 100px;
  height: 100px;

  left: calc(100% / 2 - 47px);
  top: calc(100% / 2 - 47px);
}

/************************************************************/

._3DStatueBlock {
  height: calc(100% - 158px);
}

._3DStatueBlock .SideImageBlock {
  width: 25%;
  max-width: 260px;
  flex-shrink: 0;
  opacity: 0.7;
}

._3DStatueBlock .SideImageBlock .SideImage2 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/***************************************************/
._3DStatueBlock .CenterImageBlock {
  padding-bottom: 20px;
}
._3DStatueBlock .CenterImage {
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 10s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;

  height: 50%;
}

@keyframes spinner {
  0% {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  50% {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }

  100% {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

/********************************************************/

.ButtonBlock {
  padding-right: 30px;
}

.ButtonBlock .LearnMoreButtonSmall {
  background-color: black;
}

.ButtonBlock .LearnMoreButtonBig {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 44px;

  transition: 0.5s width;
}

.ApologizeMessageBlock {
  letter-spacing: 0.1em;
}

.ApologizeMessageBlock > div:nth-child(4) {
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 10s;
}
