.MenuPanel.PageStatueItem {
  height: 128px;
}

.MenuPanel.PageStatueItem .MenuPointWrapper.PointBack {
  display: block;
  left: 70px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageStatueItem .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 55px);
  top: calc(100% / 2 - 55px);
  width: 110px;
  height: 110px;
}

.MenuPanel.PageStatueItem .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 4px);
}

.AppMobile .MenuPanel.PageStatueItem {
  height: 80px;
}

.AppMobile .MenuPanel.PageStatueItem .MenuPointWrapper.PointBack {
  left: 5px;
}

.AppMobile .MenuPanel.PageStatueItem .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageStatueItem .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px;
}

.AppMobile .MenuPanel.PageStatueItem .MenuPointWrapper.PointLogo {
  width: 70px;
  height: 70px;

  left: calc(100% / 2 - 35px);
  top: calc(100% / 2 - 35px);
}

/********************************************/

.StatueItemBlock {
  height: calc(100% - 130px);
  overflow-y: hidden;
  border-top: solid 1px rgb(30, 30, 30);
  padding: 10;
  padding-top: 0;
}

.StatueItemBlock .ItemGalleryLine .AnimationCell {
  box-shadow: inset 2px 0 2px 0 rgb(30, 30, 30);
}

.ItemLogo {
  flex-grow: 1;
  padding-top: 10%;
}

.ItemGalleryWrapper .ItemEyeIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: gray;
  opacity: 0.7;
  display: none;
}

.ItemGalleryWrapper .ItemEyeIcon:hover {
  border: 1px solid orange;
  opacity: 0.5;
}

.ItemGalleryWrapper .AnimationCell:hover .ItemEyeIcon {
  display: flex;
}

/**************************************************/
.RightBlock {
  height: 100%;
}

/***************************************************/

.PropertiesBlock {
  padding: 30px 30px 0 30px;
  width: 350px;
}

.ItemProperties .OptionLine {
  border-bottom: 1px solid white;
  padding-top: 15px;
  color: gainsboro;
}

.ItemProperties .OptionLine .OptionKey {
  opacity: 0.6;
}

.ItemProperties .OptionLineValues {
  height: 0;
  overflow-y: hidden;
  transition: height 0.7s;
  padding-top: 10px;
}

.ItemProperties .OptionLineValues.OpenValues {
  height: 60px;
}

.ItemProperties .OptionLineValues .OptionLineValue {
  padding: 3px 0 3px 0;
}

.ItemProperties .OptionLineValues .ValueChecked {
  border-radius: 50%;
  width: 20px;
  height: 20px;

  padding-bottom: 2px;
}

/*******************************************/

.ItemLabelBlock {
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 150%;
  /* identical to box height, or 96px */

  letter-spacing: 0.15em;

  overflow-x: hidden;
}

.ItemLabelBlock .ItemLabel {
  margin-left: 30px;
  max-width: 70px;
  transform: rotate(90deg);
  opacity: 0.8;
}

.ItemLabelBlock .ItemLabel_Length16 {
  margin-top: 20px;
  letter-spacing: 6px;
}

.ItemLabelBlock .ItemLabel_Length12 {
  margin-top: 20px;
  letter-spacing: 12px;
}

@media screen and (max-height: 600px) {
  .ItemLabelBlock .ItemLabel_Length16 {
    font-size: 44px;
  }
}

.ItemLabelBlock .ItemLabel_Length10 {
  margin-top: 20px;
  letter-spacing: 12px;
}

.ItemLabelBlock .ItemLabel_Length8 {
  margin-top: 20px;
  letter-spacing: 20px;
}

.ItemLabelBlock .ItemLabel_Length6 {
  margin-top: 20px;
  letter-spacing: 26px;
}

/***************************************/
.StatueModalNavigationButton {
  font-size: 50px;
  border-radius: 50%;
  background-color: gray;
  width: 65px;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15px;
}

/******************************************/
/************* M O B I L E ****************/

.StatueItemMobileBlock {
  height: calc(100vh - 128px);
}

.StatueItemMobileBlock .StatueImagesBlock {
  height: calc(100% - 100px);
  overflow-x: hidden;
}

.StatueItemMobileBlock .ImagesPoints {
  height: 30px;
}

.StatueItemMobileBlock .ImagesPoints .ImagePoint {
  width: 12px;
  height: 12px;
  border: 2px solid gray;
  border-radius: 50%;
  background-color: transparent;
  transition: background 0.5s;
}

.StatueItemMobileBlock .ImagesPoints .ImagePoint.Current {
  background-color: white;
}

.StatueItemMobileBlock .StatueImagesBlock .ImageWrapper {
  flex: 0 0 100%;
}

.StatueItemMobileBlock .StatueImagesBlock .ImageWrapper .HeightControlMobileWrapper {
  max-height: 100%;
  min-height: 65%;
}

.StatueItemMobileBlock .StatueImagesBlock .StatueImagesRow {
  transition: left 0.5s;
}

.StatueItemMobileBlock .OptionsBlock {
  bottom: 0;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;

  border: solid white 2px;
  border-bottom-color: transparent;

  padding: 0 40px 10px 40px;
}

.BaseButton.MobileOptionsExpanderButton {
  height: 28px;
}

.MobileOptionsExpanderButton .OptionsExpander {
  width: 30px;
  height: 10px;
  border-bottom: 3px solid white;
}

/* .MobileOptionsExpanderButton:hover .OptionsExpander {
  border-color: gray;
} */

.StatueItemMobileBlock .BlockTitle {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.14em;
  text-align: left;
  text-transform: uppercase;
}

.StatueItemMobileBlock .BlockText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 100;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: left;
  opacity: 0.7;
}

.StatueItemMobileBlock .Buttons > *:not(.Grow) {
  width: 48%;
}

.StatueItemMobileBlock .Buttons .BaseButton {
  height: 40px;
  font-size: 12px;
}

.StatueItemMobileBlock .OptionsBlock {
  background-color: black;
}

.StatueItemMobileBlock .OptionsBlock.isClosed .OnlyOnOpen {
  display: none;
}

.StatueItemMobileBlock .StatueOptions {
  padding-top: 30px;
}

.StatueItemMobileBlock .StatueOptionsRow {
  padding-bottom: 24px;
}

.StatueItemMobileBlock .StatueOptions .StatueOptionInfo {
  padding-left: 20px;
}

.StatueItemMobileBlock .StatueOptions .StatueOptionValue {
  color: rgba(255, 255, 255, 0.7)
}

.StatueItemMobileBlock .StatueOptionBlock {
  width: 50%
}


/********************************/
/* .AppMobile {
  background-color: yellow;
}

.StatueItemMobileBlock {
  background-color: green;
} */
