/******************* MENU ***************************/
.MenuPanel.PageLanding {
  height: 158px;
}

.MenuPanel.PageLanding .MenuPointWrapper.PointTwoLines {
  display: block;
  left: 120px;
  top: calc(100% / 2 - 7px);
}

.MenuPanel.PageLanding .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
}

.MenuPanel.PageLanding .MenuPointWrapper.PointSearch {
  display: block;
  left: calc(100% - 120px - 20px);
  top: calc(100% / 2 - 30px);
}

.App.AppMobile .MenuPanel.PageLanding {
  height: 230px;
}

.App.AppMobile .MenuPanel.PageLanding .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 35px);
  top: 28px;
  width: 70px;
  height: 70px;
}

.App.AppMobile .MenuPanel.PageLanding .MenuPointWrapper.PointWhiteLotusTitle {
  display: block;
  left: calc(100% / 2 - 110px);
  top: 128px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 8px;
}

.App.AppMobile .MenuPanel.PageLanding .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% / 2 - 30px);
  top: 200px;
}

.App.AppMobile
  .MenuPanel.PageLanding
  .MenuPointWrapper.PointTwoLines
  .TwoLinesLine {
  border-width: 1.5px;
}

.App.AppMobile
  .MenuPanel.PageLanding
  .MenuPointWrapper.PointTwoLines
  .TwoLinesLine:nth-child(2) {
  width: 70%;
  left: 12px;
  margin-top: 12px;
}

.App.AppMobile .MenuPanel.PageLanding .MenuPointWrapper.PointSearch {
  display: none;
}

/**********************************************/
.IndividuallyHandcraftedLabelWrapper {
  margin-top: 20px;
  gap: 10px;
}
.IndividuallyHandcraftedLabelWrapper > div {
  font-size: 8px;
  letter-spacing: 11px;
}

/***********************************************/
.LandingBlock {
  height: calc(100vh);
  overflow-x: hidden;
}

.LandingWrapper {
  height: calc(100vh);
}

.Statue {
  height: calc(100vh);
  top: 0;
  background-color: black;
}

.ScrollState .Statue {
  transition: transform 0.9s, opacity 0.9s;
}

.Statue img.StatueImage {
  top: 0;
  height: 100%;
  transition: height 0.5s, top 0.5s;
}

.Statue.ScrollingUp {
  opacity: 0.2;
  /* transform: translate(40%, -40%); */
  /* transform: scale(0.5, 0.5) translate(60%, -60%); */
}

.Statue.ScrollingDown + .Statue {
  opacity: 0.2;
  /* transform: scale(0.5, 0.5) translate(60%, 60%); */
}

.Statue.ScrollingUp + .Statue {
  animation: GoingUp 1s;
  z-index: 900;
}

.Statue.ScrollingDown {
  animation: GoingDown 1s;
  z-index: 900;
}

@keyframes GoingUp {
  0% {
    top: 0;
  }

  100% {
    top: calc(-100vh);
  }
}

@keyframes GoingDown {
  0% {
    top: 0;
  }

  100% {
    top: calc(100vh);
  }
}

.StatueDescrHWrapper {
  justify-content: center;
}
.StatueDescrHWrapper .StatueDescrContentWrapper {
  transition: font-size 0.5s;
}

.StatueDescrHWrapper .StatueDescrContentWrapper .MainLabel {
  font-size: 48px;
}

@media only screen and (max-width: 1360px) {
  .StatueDescrHWrapper .StatueDescrContentWrapper {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1240px) {
  .StatueDescrHWrapper .StatueDescrContentWrapper {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 1120px) {
  .StatueDescrHWrapper .StatueDescrContentWrapper {
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 1000px) {
  .StatueDescrHWrapper .StatueDescrContentWrapper {
    font-size: 0.5em;
  }
}

@media only screen and (max-width: 880px) {
  .StatueDescrHWrapper .StatueDescrContentWrapper {
    font-size: 0.4em;
  }
}

/***************************************************/
.LandingMobileBlock .MobileLandingFooter {
  padding-top: 130px;
  gap: 40px;
}

.LandingMobileBlock .FooterTitle {
  margin-bottom: 10px;
}

.LandingMobileBlock .StatueMobile {
  height: 220px;
  overflow-x: hidden;
}

.LandingMobileBlock .StatueMobile .StatueImage {
  position: absolute;
  height: 100%;
}

.LandingMobileBlock .StatueMobile .StatueButtonBlock {
  position: absolute;
  height: 100%;
  width: 62px;
  background-color: #93919187;
  opacity: 0.8;
}

.LandingMobileBlock .StatueMobile .StatueButtonBlock div {
  font-family: Inter;
  transform: rotate(-90deg);
}
