.Logo {
  width: 125px;
  height: 125px;
  background-color: transparent;
  border-radius: 50%;
}

.Logo.IsRef:hover {
  opacity: 0.6;
}

.LogoSize1 {
  width: 102px;
  height: 102px;
}

.LogoSize2 {
  width: 125px;
  height: 125px;
}

.LogoSize3 {
  width: 150px;
  height: 150px;
}

.LogoSize4 {
  width: 175px;
  height: 175px;
}

.LogoSize5 {
  width: 200px;
  height: 200px;
}

.LogoSize6 {
  width: 223px;
  height: 223px;
}

.LogoSize7 {
  width: 246px;
  height: 246px;
}

.LogoSize10 {
  width: 100%;
  height: 100%;
}

.Logo svg {
  width: 100%;
  height: 100%;
}
