.WisdomPageBlock {
  padding: 60px 100px 30px 100px;
}

.WisdomPageBlock .FirstImage {
  width: 75%;
}

.WisdomPageBlock .ArticleBlock {
  padding-top: 50px;
  padding-left: 70px;
  z-index: 10;
}

.WisdomPageBlock .ArticleBlock .SmallArticleBlock {
  width: 400px;
  padding-left: 30px;
}

.WisdomPageMobileBlock {
  padding-bottom: 80px;
}

.WisdomPageMobileBlock .WisdomPageArticle {
  position: relative;
}

.WisdomPageImage {
  position: absolute;
  top: 60px
}

.WisdomPageMobileBlock .WisdomPageArticle .ArticleContent {
  margin-top: calc(30vh);
  z-index: 10;
}
