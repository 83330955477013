.MenuPanel.PageOmMani {
  height: 128px;
}

.MenuPanel.PageOmMani .MenuPointWrapper.PointBack {
  display: block;
  left: 70px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageOmMani .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
  width: 125px;
  height: 125px;
}

.MenuPanel.PageOmMani .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 4px);
}

.AppMobile .MenuPanel.PageOmMani .MenuPointWrapper.PointBack {
  left: 5px;
}

.AppMobile .MenuPanel.PageOmMani .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageOmMani .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px;
}

.AppMobile .MenuPanel.PageOmMani .MenuPointWrapper.PointLogo {
  width: 100px;
  height: 100px;

  left: calc(100% / 2 - 47px);
  top: calc(100% / 2 - 47px);
}

/***********************************/

.OmManiPage .ArticleBlock .SmallArticleBlock {
  width: 430px;
  padding-top: 30px;
}
