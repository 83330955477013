/*****************************************/
.MenuPanel.PageContactUs {
  height: 128px;
}

.MenuPanel.PageContactUs .MenuPointWrapper.PointBack {
  display: block;
  left: 70px;
  top: calc(100% / 2 - 25px);
}

.MenuPanel.PageContactUs .MenuPointWrapper.PointLogo {
  display: block;
  left: calc(100% / 2 - 62px);
  top: calc(100% / 2 - 62px);
}

.MenuPanel.PageContactUs .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(100% - 120px - 30px);
  top: calc(100% / 2 - 4px);
}

/*** ~~~~~~~~~~~~~ ***/
.AppMobile .MenuPanel.PageContactUs .MenuPointWrapper.PointBack {
  left: 5px;
}

.AppMobile .MenuPanel.PageContactUs .MenuPointWrapper.PointTwoLines {
  left: calc(100% - 5px - 60px);
}
.AppMobile .MenuPanel.PageContactUs .TwoLinesLine:nth-child(2) {
  width: 40px;
  margin-left: 24px;
}

.AppMobile .MenuPanel.PageContactUs .MenuPointWrapper.PointLogo {
  width: 100px;
  height: 100px;

  left: calc(100% / 2 - 50px);
  top: calc(100% / 2 - 50px);
}

/***********************************************/

.ContactUsBlock {
  height: calc(100vh - 128px);
  padding: 80px 120px 70px 120px;
  font-size: 22px;
  overflow-y: hidden;
}

.ContactLinks {
  padding-top: 100px;
  gap: 25px;
}

.ContactLinks .LinksBlock {
  z-index: 10;
}

.ContactLinks .ContactLinkButton {
  width: 300px;
  max-width: 90%;
  justify-content: center;
}

.ContactUsBlock .Contact {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  gap: 15px;
  color: white;
}

.ContactUsBlock .Contact a {
  color: gray;
}

.ContactUsBlock .ContactLogo {
  width: 50px;
  height: 50px;
}

.ContactUsBlock .ContactLogo.Instagram {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}

/***********************/
.ContactUsMobileBlock {
  height: calc(100vh - 128px);
  overflow: hidden;
}

.ContactUsMobileBlock .ContactLinks {
  padding-top: 10px;
  overflow: hidden;
  padding-bottom: 50px;
}
