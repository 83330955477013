.BaseButton.MovingBottom {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 150.02%;
  /* or 27px */

  letter-spacing: 0.15em;
  width: 220px;
  border: none;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );

  border-image-slice: 1;
}

.BaseButton.MovingBottom:hover {
  color: white;
  animation: MovingGradient 1.5s ease-out infinite;
  animation-delay: 0.5s;
}

@keyframes MovingGradient {
  0% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );

    border-image-slice: 1;
  }

  25% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );

    border-image-slice: 1;
  }

  50% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );

    border-image-slice: 1;
  }

  75% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    );

    border-image-slice: 1;
  }

  100% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255)
    );

    border-image-slice: 1;
  }
}

@keyframes MovingGradient2 {
  0% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );

    border-image-slice: 1;
  }

  50% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );

    border-image-slice: 1;
  }

  100% {
    border-image: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    );

    border-image-slice: 1;
  }
}
