/*****************************************/
.MenuPanel.PageMenu {
  height: 108px;
}

.MenuPanel.PageMenu .MenuPointWrapper.PointTwoLines {
  display: block;
  left: calc(90% - 30px);
  top: 96px;
}

.App:not(.AppMobile) .MenuPanel.PageMenu .MenuPointWrapper.PointLogo {
  display: flex;
  left: 0;
  top: 0;
  width: calc(60%);
  height: calc(100vh);
  padding-left: 15%;
  padding-right: 15%;
  align-items: center;
  opacity: 0.5;
}

.App.AppMobile .MenuPanel.PageMenu {
  height: 230px;
}

.App.AppMobile .MenuPanel.PageMenu .MenuPointWrapper.PointTwoLines {
  display: flex;
  justify-content: center;
  left: 0;
  width: 100%;
  top: 170px;
  margin-left: 55px;
}

.App.AppMobile .MenuPanel.PageMenu .MenuPointWrapper.PointLogo {
  display: block;
  top: 30px;
  width: 100%;
  height: 80px;
}

/*****************************************/

.MenuBlock {
  padding-right: calc(10%);
  height: calc(100% - 108px);
  overflow-y: visible;
}

.MenuPart {
  padding-top: calc(100vh / 2 - 120px - 100px);
}

.PageScreen {
  z-index: 600;
  position: absolute;
  display: none;
  top: -108px;
  left: -150%;
  width: 150%;
  height: calc(100vh);
  background-color: black;
  padding-left: 50px;
}

.MenuPoint {
  font-family: Inter;
  height: 60px;
  font-size: 32px;
  line-height: 58px;
  letter-spacing: 0.18em;
  text-align: justify;
}

.MenuPointBlock .HoverShow:hover + .PageScreen {
  display: flex;
  align-items: center;
}

.MenuPointBlock,
.MobileMenuBlock {
  opacity: 1;
  transition: opacity 3s;
}

.MenuPointBlock.IsFirstCall,
.MobileMenuBlock.IsFirstCall {
  opacity: 0;
}
